const authGateway = 'soma-dev-api-auth.azurewebsites.net';
const portalGateway = 'soma-dev-portal-api.azurewebsites.net';

export const GATEWAY_SOMA_AUTH = `https://${authGateway}/v1`;
export const GATEWAY_SOMA_PORTAL_V1 = `https://${portalGateway}/api/v1`;
export const GATEWAY_SOMA_PORTAL_V3 = `https://${portalGateway}/api/v3`;
export const environment = {
    production: false,
    ENV_NAME: 'default',
    TOKEN_BASE: `${GATEWAY_SOMA_AUTH}/tokens`,
    HOME_BASE: `${GATEWAY_SOMA_PORTAL_V1}/home`,
    TENANT_BASE: `${GATEWAY_SOMA_PORTAL_V1}/tenants`,
    TENANT_ADVANTAGE_BASE: `${GATEWAY_SOMA_PORTAL_V1}/tenants/advantages`,
    LOGIN_BASE: `${GATEWAY_SOMA_PORTAL_V1}/login`,
    LOGIN_BASE_V3: `${GATEWAY_SOMA_PORTAL_V3}/login`,
    OPERATION_BASE: `${GATEWAY_SOMA_PORTAL_V1}/operations`,
    PERSON_BASE: `${GATEWAY_SOMA_PORTAL_V1}/people`,
    OPERATOR_BASE: `${GATEWAY_SOMA_PORTAL_V1}/operators`,
    PROFILE_BASE: `${GATEWAY_SOMA_PORTAL_V1}/profiles`,
    POLICY_BASE: `${GATEWAY_SOMA_PORTAL_V1}/policies`,

    SCORE_RESIDUE_BASE: `${GATEWAY_SOMA_PORTAL_V1}/scores/residues`,
    SCORE_RESIDUE_CATEGORY_BASE: `${GATEWAY_SOMA_PORTAL_V1}/scores/residues/categories`,
    SCORE_COUPONS: `${GATEWAY_SOMA_PORTAL_V1}/scores/coupons`,
    SCORE_EXTRA: `${GATEWAY_SOMA_PORTAL_V1}/scores/extra`,
    PLACE_BASE: `${GATEWAY_SOMA_PORTAL_V1}/places`,
    PLACE_TYPE_BASE: `${GATEWAY_SOMA_PORTAL_V1}/places/type`,
    PARTNER_BASE: `${GATEWAY_SOMA_PORTAL_V1}/partners`,
    REWARDS_BASE: `${GATEWAY_SOMA_PORTAL_V1}/rewards`,
    REWARDS_CATEGORY_BASE: `${GATEWAY_SOMA_PORTAL_V1}/rewards/categories`,
    FORGOT_PASSWORD_BASE: `${GATEWAY_SOMA_PORTAL_V1}/login/forgotPassword`,
    RESET_PASSWORD_BASE: `${GATEWAY_SOMA_PORTAL_V1}/login/resetPassword`,
    TYPE_OF_PARTICIPANT_BASE: `${GATEWAY_SOMA_PORTAL_V1}/typesOfParticipants`,
    COOPERATIVE_BASE: `${GATEWAY_SOMA_PORTAL_V1}/cooperatives`,
    INVOICE_BASE: `${GATEWAY_SOMA_PORTAL_V1}/invoices`,
    STOCK_BASE: `${GATEWAY_SOMA_PORTAL_V1}/stocks`,
    FAQ_BASE: `${GATEWAY_SOMA_PORTAL_V1}/faq`,
    QUIZ_BASE: `${GATEWAY_SOMA_PORTAL_V1}/quiz`,
    auth_params: {
        xConsumerId: 'ca08acb4-6f11-4509-afbf-3f66bbb06217',
        consumerToken: '4948CC58-333C-4B24-B7D5-86A3ACC55C61',
    },
    anonymousPersonId: '0fcabf1f-4e64-4c93-9e9a-eb61efbde9b8',
    anonymousAccountId: '0abae4d7-517f-4560-a3c1-02c6391ff783',
};
