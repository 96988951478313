import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-footer-form',
    templateUrl: './footer-form.component.html',
    styleUrl: './footer-form.component.scss',
})
export class FooterFormComponent {
    @Input() loading: boolean = false;
    @Input() isValid: boolean = false;
    @Input() firstButtonText: string = 'Cancelar';
    @Input() secondButtonText: string = 'Confirmar';
    @Input() hasSecondButton: boolean = true;
    @Input() route: string = '';
    @Input() secondButtonEmit: boolean = false;
    @Input() claimName: string = '';
    @Output() firstButtonEmitter = new EventEmitter<string>();
    @Output() secondButtonEmitter = new EventEmitter<string>();

    confirm() {
        this.secondButtonEmitter.emit(this.route);
    }

    cancel() {
        this.firstButtonEmitter.emit(this.route);
    }
}
