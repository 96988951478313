import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getMobileOperatingSystem } from 'src/app/multi-tenant-web-portal/shared/utils/device-checker';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss']
})
export class TextFieldComponent {
  @Input() fieldName: string = 'blank-input-nane';
  @Input() fieldId?: string;
  @Input() fieldNgModel: string | number = '';
  @Input() fieldLabel?: string;
  @Input() fieldType!: string;
  @Input() fieldMask?: string;
  @Input() fieldDropMask: boolean = false;
  @Input() fieldHaveMask: any = '';
  @Input() isRequired: boolean = false;
  @Input() isPassword?: boolean = false;
  @Input() isSearch?: boolean = false;
  @Input() isSearchCleanIcon?: boolean = false;
  @Input() showPassword?: boolean;
  @Input() isDisabled: boolean = false;
  @Input() isPhoneFiled: boolean = false;
  @Input() fieldPlaceholder: string = '';
  @Input() isEmail?: boolean = false;
  @Input() fieldHaveDecimalMarker: boolean = false;
  @Input() errorMessage: string = '';
  @Input() isForm: boolean = false;
  @Input() formName!: FormGroup;
  @Input() inputMode: string = 'text';
  @Output() value = new EventEmitter<string | number>();

  isIos: boolean = getMobileOperatingSystem() !== 'Android';

  sendValue(disabledValue: string = '') {
    this.isDisabled ? this.value.emit(disabledValue) : this.value.emit(this.fieldNgModel);
  }

  updatePasswordEyes(status: boolean) {
    this.showPassword = status;
    this.fieldType = status ? 'tel' : 'password';
  }

  clearInputSearch(disabledValue: string = '') {
    this.value.emit(disabledValue);
  }
}
