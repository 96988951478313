import { Routes } from '@angular/router';
import { BASE_ROUTES } from './base-routes';
import { DASHBOARD_ROUTES } from './dashboard-routes';
import { PEOPLE_ROUTES } from './people-routes';
import { REWARDS_ROUTES } from './rewards-route';
import { TENANTS_ROUTES } from './tenant-routes';

export const ROUTES: Routes = BASE_ROUTES;

export const DASH_ROUTES: Routes = DASHBOARD_ROUTES;

export const PERSON_ROUTES: Routes = PEOPLE_ROUTES;

export const REWARD_ROUTES: Routes = REWARDS_ROUTES;
export const TENANT_ROUTES: Routes = TENANTS_ROUTES;
