import { Routes } from '@angular/router';
import { authGuard } from '../../core/guards/auth.guard';
import { hasClaim } from '../../core/guards/role.guard';
export const DASHBOARD_ROUTES: Routes = [
    {
        path: 'today',
        canActivate: [authGuard],
        canMatch: [],
        loadChildren: () =>
            import(
                '../../pages/dashboard-page/pages/dashboard-today/dashboard-today.module'
            ).then((m) => m.DashboardTodayModule),
    },
    {
        path: 'operations',
        canActivate: [authGuard, hasClaim('CanCreateOperation')],
        loadChildren: () =>
            import('../../features/operations/operations.module').then(
                (m) => m.OperationsModule
            ),
    },
    {
        path: 'contact-us',
        loadChildren: () =>
            import('../../pages/contact-us/contact-us.module').then(
                (m) => m.ContactUsModule
            ),
    },
    {
        path: 'people',
        canActivate: [authGuard],
        loadChildren: () =>
            import('../../pages/people-page/people-page.module').then(
                (m) => m.PeoplePageModule
            ),
    },
    {
        path: 'materials',
        canActivate: [authGuard],
        loadChildren: () =>
            import('../../pages/materials-page/materials-page.module').then(
                (m) => m.MaterialsPageModule
            ),
    },
    {
        path: 'rewards',
        canActivate: [authGuard],
        loadChildren: () =>
            import('../../pages/rewards-page/rewards-page.module').then(
                (m) => m.RewardsPageModule
            ),
    },
    {
        path: 'invoices',
        canActivate: [authGuard],
        loadChildren: () =>
            import('../../pages/invoices-page/invoices-page.module').then(
                (m) => m.InvoicesPageModule
            ),
    },
    {
        path: 'reports',
        canActivate: [authGuard],
        loadChildren: () =>
            import('../../pages/reports-page/reports-page.module').then(
                (m) => m.ReportsPageModule
            ),
    },
    {
        path: 'places',
        canActivate: [authGuard],
        loadChildren: () =>
            import('../../features/places/places-page.module').then(
                (m) => m.PlacesPageModule
            ),
    },
    {
        path: 'type-of-participants',
        canActivate: [authGuard],
        loadChildren: () =>
            import(
                '../../pages/type-of-participants-page/type-of-participants-page.module'
            ).then((m) => m.TypeOfParticipantsPageModule),
    },
    {
        path: 'tenants',
        canActivate: [authGuard],
        loadChildren: () =>
            import(
                '../../pages/tenant-advantages-page/tenant-advantages-page-routing.module'
            ).then((m) => m.TenantAdvantagesPageRoutingModule),
    },
    {
        path: 'local-operators',
        canActivate: [authGuard],
        loadChildren: () =>
            import('../../features/local-operators/local-operator.module').then(
                (m) => m.LocalOperatorModule
            ),
    },
    {
        path: 'score-coupon',
        canActivate: [authGuard],
        loadChildren: () =>
            import('../../features/score-coupon/score-coupon.module').then(
                (m) => m.ScoreCouponModule
            ),
    },
    {
        path: 'partners',
        canActivate: [authGuard],
        loadChildren: () =>
            import('../../features/partners/partners.module').then(
                (m) => m.PartnersModule
            ),
    },
    {
        path: 'policies',
        canActivate: [authGuard],
        loadChildren: () =>
            import('../../features/policies/policies.module').then(
                (m) => m.PoliciesModule
            ),
    },
    {
        path: 'mobile-app',
        canActivate: [authGuard],
        loadChildren: () =>
            import('../../features/mobile-app/mobile-app.module').then(
                (m) => m.MobileAppModule
            ),
    },
    {
        path: '',
        redirectTo: 'today',
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: 'today',
        pathMatch: 'full',
    },
];
