import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/multi-tenant-web-portal/shared/interfaces/user/user.interface';

@Component({
    selector: 'app-sidebar-items',
    templateUrl: './sidebar-items.component.html',
    styleUrl: './sidebar-items.component.scss',
})
export class SidebarItemsComponent implements OnInit {
    user: User = JSON.parse(localStorage.getItem('user')!);

    ngOnInit(): void {
        this.onscroll(window.document, this.navbarlinksActive);
    }

    select = (el: string, all: boolean = false): any | any[] => {
        el = el.trim();
        if (all) {
            return [window.document.querySelectorAll(el)];
        } else {
            return document.querySelector(el);
        }
    };

    navbarlinks: NodeListOf<HTMLElement> = this.select(
        '#nav-items .scrollto',
        true
    );

    navbarlinksActive = (): void => {
        const position: number = window.scrollY + 200;
        this.navbarlinks.forEach((navbarlink: any) => {
            if (!navbarlink.hash) return;
            const section: HTMLElement | null = this.select(navbarlink.hash);
            if (!section) return;
            if (
                position >= section.offsetTop &&
                position <= section.offsetTop + section.offsetHeight
            ) {
                navbarlink.classList.add('active');
            } else {
                navbarlink.classList.remove('active');
            }
        });
    };

    onscroll = (el: any, listener: EventListener) => {
        el.addEventListener('scroll', listener);
    };

    hideSidebar() {
        window.document.body.classList.toggle('toggle-sidebar');
    }

    menuItems: any[] = [
        {
            id: 'operations',
            title: 'Operações',
            icon: 'bi bi-display',
            claim: 'CanCreateOperation',
            items: [
                {
                    name: 'Entrega de materiais',
                    path: 'dashboard/operations/score-residues',
                    claim: 'CanCreateOperation',
                },
                {
                    name: 'Resgate de recompensas',
                    path: 'dashboard/operations/rewards',
                    claim: 'CanCreateOperationReward',
                },
                {
                    name: 'Resgate pelo aplicativo',
                    path: 'dashboard/operations/reserves',
                    claim: 'CanCreateOperationReward',
                },
                {
                    name: 'Entrega retroativa',
                    path: 'dashboard/operations/backdated-delivery',
                    claim: 'CanInsertVolumeInPast',
                },
                {
                    name: 'Pontuação extra',
                    path: 'dashboard/operations/extra-score',
                    claim: 'CanCreateOperationExtra',
                },
            ],
        },
        {
            id: 'participants',
            title: 'Participantes',
            icon: 'bi bi-people',
            claim: 'CanReadPerson',
            items: [
                {
                    name: 'Consultar participantes',
                    path: 'dashboard/people/participants',
                    claim: 'CanReadPerson',
                },
                {
                    name: 'Consultar tipo de participantes',
                    path: 'dashboard/type-of-participants',
                    claim: 'CanReadTypeOfParticipant',
                },
            ],
        },
        {
            id: 'users',
            title: 'Usuários',
            icon: 'bi bi-person-gear',
            claim: 'CanReadOperator',
            items: [
                {
                    name: 'Consultar usuários',
                    path: 'dashboard/people/users',
                    claim: 'CanReadOperator',
                },
                {
                    name: 'Gerenciar perfis e permissões',
                    path: 'dashboard/policies',
                    claim: 'CanReadPolicy',
                },
            ],
        },
        {
            id: 'materials',
            title: 'Materiais',
            icon: 'bi bi-recycle',
            claim: 'CanReadScoreResidue',
            items: [
                {
                    name: 'Consultar materiais',
                    path: 'dashboard/materials',
                    claim: 'CanReadScoreResidue',
                },
                // {
                //     name: 'Consultar categoria de materiais',
                //     path: 'dashboard/materials/categories',
                //     claim: 'CanReadScoreResidueCategory',
                // },
            ],
        },
        {
            id: 'rewards',
            title: 'Recompensas',
            icon: 'bi bi-gift',
            claim: 'CanReadReward',
            items: [
                {
                    name: 'Gerenciar recompensas',
                    path: 'dashboard/rewards',
                    claim: 'CanReadReward',
                },
                {
                    name: 'Consultar categoria de recompensas',
                    path: 'dashboard/rewards/categories',
                    claim: 'CanReadRewardCategory',
                },
                {
                    name: 'Consultar clube de benefícios',
                    path: 'dashboard/tenants/advantages',
                    claim: 'CanReadTenantAdvantage',
                    show: this.user.hasTenantAdvantage,
                },
                {
                    name: 'Consultar cupons',
                    path: 'dashboard/score-coupon',
                    claim: 'CanReadScoreCoupon',
                },
                {
                    name: 'Consultar parceiros',
                    path: 'dashboard/partners',
                    claim: 'CanReadPartner',
                },
            ],
        },
        {
            id: 'places',
            title: 'Espaços',
            icon: 'bi bi-geo-alt',
            claim: 'CanReadPlace',
            items: [
                {
                    name: 'Consultar espaços',
                    path: 'dashboard/places',
                    claim: 'CanReadPlace',
                },
                {
                    name: 'Compras de estoque',
                    path: 'dashboard/invoices',
                    claim: 'CanReadInvoice',
                },
                {
                    name: 'Consultar operadores locais',
                    path: 'dashboard/local-operators',
                    claim: 'CanReadCooperative',
                },
            ],
        },
        {
            id: 'reports',
            title: 'Relatórios',
            icon: 'bi bi-graph-up',
            claim: 'CanSeeStatisticReport',
            items: [
                {
                    id: 'statistic',
                    title: 'Estatístico',
                    claim: 'CanSeeStatisticReport',
                    subitems: [
                        {
                            id: 'operational',
                            name: 'Operacional',
                            path: 'dashboard/reports/operational/statistic',
                            claim: 'CanSeeStatisticReport',
                        },
                        {
                            id: 'impacts',
                            name: 'Impactos ambientais e socioeconômicos',
                            path: 'dashboard/reports/environmental-socioeconomic/statistic',
                            claim: 'CanSeeStatisticReport',
                        },
                        {
                            id: 'volume-material-recyclable',
                            name: 'Volume por tipo de material reciclável',
                            path: 'dashboard/reports/volume/statistic',
                            claim: 'CanSeeStatisticReport',
                        },
                        {
                            id: 'rewards-category',
                            name: 'Resgates por categoria',
                            path: 'dashboard/reports/reward/statistic',
                            claim: 'CanSeeStatisticReport',
                        },
                    ],
                },
                {
                    id: 'volume',
                    title: 'Volume',
                    claim: 'CanSeeVolumeReport',
                    subitems: [
                        {
                            id: 'volume-material',
                            name: 'Por tipo de material',
                            path: 'dashboard/reports/volume/material',
                            claim: 'CanSeeVolumeReport',
                        },
                        {
                            id: 'volume-time',
                            name: 'Por tempo',
                            path: 'dashboard/reports/volume/time',
                            claim: 'CanSeeVolumeReport',
                        },
                    ],
                },
                {
                    id: 'socioeconomic-environment',
                    title: 'Ambiental e Socioeconômico',
                    claim: 'CanSeeEnvironmentReport',
                    subitems: [
                        {
                            id: 'environment',
                            name: 'Impacto ambiental',
                            path: 'dashboard/reports/environmental-socioeconomic/environmental',
                            claim: 'CanSeeEnvironmentReport',
                        },
                        {
                            id: 'environment-place',
                            name: 'Impacto ambiental por local',
                            path: 'dashboard/reports/environmental-socioeconomic/environmental/place',
                            claim: 'CanSeeEnvironmentReport',
                        },
                        {
                            id: 'socioeconomic',
                            name: 'Socioeconômico',
                            path: 'dashboard/reports/environmental-socioeconomic/socioeconomic',
                            claim: 'CanSeeEnvironmentReport',
                        },
                        {
                            id: 'socioeconomic-place',
                            name: 'Socioeconômico por local',
                            path: 'dashboard/reports/environmental-socioeconomic/socioeconomic/place',
                            claim: 'CanSeeEnvironmentReport',
                        },
                    ],
                },
                {
                    id: 'operationals-report',
                    title: 'Operacional',
                    claim: 'CanSeeOperationalReport',
                    subitems: [
                        {
                            id: 'time',
                            name: 'Por tempo',
                            path: 'dashboard/reports/operational/time',
                            claim: 'CanSeeOperationalReport',
                        },
                        {
                            id: 'monthly',
                            name: 'Evolução mensal',
                            path: 'dashboard/reports/operational/monthly',
                            claim: 'CanSeeOperationalReport',
                        },
                    ],
                },
                {
                    id: 'participants-report',
                    title: 'Participante',
                    claim: 'CanSeeParticipantReport',
                    subitems: [
                        {
                            id: 'age',
                            name: 'Perfil etário dos participantes',
                            path: 'dashboard/reports/participant/age',
                            claim: 'CanSeeParticipantReport',
                        },
                        {
                            id: 'engagement',
                            name: 'Por nível de engajamento',
                            path: 'dashboard/reports/participant/engagement',
                            claim: 'CanSeeParticipantReport',
                        },
                    ],
                },
            ],
        },
        {
            id: 'mobile-app',
            title: 'Aplicativo',
            icon: 'bi bi-phone',
            claim: 'CanReadCMS',
            items: [
                {
                    name: 'FAQ',
                    path: 'dashboard/mobile-app/help',
                    claim: 'CanReadHelp',
                },
                {
                    name: 'Categorias de FAQ',
                    path: 'dashboard/mobile-app/help-category',
                    claim: 'CanReadHelpCategory',
                },
                {
                    name: 'Perguntas Quiz',
                    path: 'dashboard/mobile-app/quiz',
                    claim: 'CanReadQuestion',
                },
            ],
        },
    ];
}
