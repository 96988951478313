<div class="container">
    <div class="row">
        <div class="footer-separator fixed-bottom footer-background footer-form">
            <div class="footer p-4 m-0">
                <div class="col-md-1 col-sm-12 ml-80">
                    <button type="button" class="btn btn-default-tertiary" (click)="cancel()">
                        {{firstButtonText}}
                    </button>
                </div>
                @if(hasSecondButton){
                <div class="col-md-1 col-sm-12 mr-400" *appHasClaim="claimName">
                    @if(secondButtonEmit){
                    <button class="btn btn-default-primary footer-button" type="submit" (click)="confirm()"
                        [disabled]="loading || !isValid">
                        @if(loading){
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        }
                        {{secondButtonText}} <i class="bi bi-chevron-right pl-8px"></i>
                    </button>
                    }@else{
                    <button class="btn btn-default-primary footer-button" type="submit"
                        [disabled]="loading || !isValid">
                        @if(loading){
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        }
                        {{secondButtonText}} <i class="bi bi-chevron-right pl-8px"></i>
                    </button>
                    }
                </div>
                }
            </div>
        </div>
    </div>
</div>